import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { parse } from 'query-string';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';
import AdButler from '../../components/ThankyouPages/AdButler';
import adbutlerZones from '../../utils/adbutlerZones';
import { webHookTrack } from '../../utils/dynamic/webhookTrack';
import storage from '../../utils/storage';
import { g4EventTracking } from '../../utils/dynamic/g4Tracking';
import { loadScript } from '../../utils/loadScript';
import CredibleDisclosure from '../../components/ThankyouPages/CredibleDisclosure';

const RateContainer = styled.main`
  font-family: 'Nunito Sans', sans-serif;
  background: none;
  width: 100%;

  h1 {
    color: #ae1a1a;
    font-weight: normal;
    font-size: 2.1em;
    text-align: center;
  }
  p {
    text-align: center;
  }
`;

// fake vwo functions to allow vwo to fail on this silently w/o blowing up Sentry
try {
  window.alterConfig = () => {
    // fail silently
  };
  window.alterQuestions = () => {
    // fail silently
  };
  window.addQuestion = () => {
    // fail silently
  };
  window.removeQuestion = () => {
    // fail silently
  };
  window.changeFromSlider = () => {
    // fail silently
  };
} catch (error) {
  // fail silently
}
const Rate = ({ config, path }) => {
  const { scData, isSCLabs, surveyName } = config;
  const { siteVertical } = scData;
  const [adIds, setAdIds] = useState([]);
  const [params, setParams] = useState({});
  const [isILX, setIsILX] = useState(false);
  const [isQsTy, setQsTy] = useState(false);

  const IlxRateZones = (query, search) => {
    // please note that you will need to make updates in ilx.js and nextpage.js as well
    if (
      // always show ilx zones for roofing
      siteVertical === 'hs_roofing' ||
      // Always so ILX zones for dpa
      siteVertical === 'dpa' ||
      // always show ilx zones for solar renter ejection but not has solar ejection
      (siteVertical === 'hs_solar' && !search.includes('HasSolar')) ||
      // test for window ejection
      (siteVertical === 'hs_window' && search.includes('zipRedirect')) ||
      (siteVertical === 'hs_br' && query.version === '4') ||
      surveyName === 'mortgage.ebridgefinancial.com'
    ) {
      return true;
    }
    return false;
  };

  const checkRateExperiment = async () =>
    window?.jtkResults?.experimentId ?? '';

  const findRedirectCondition = () => {
    const redirectConditions = {
      HasSolar: 'Has Solar',
      notInsured: 'Not Insured',
      noMed: 'No Med',
      renter: 'Renter',
      creditEject: 'Credit Eject',
      zipRedirect: 'Zip Redirect',
    };

    const searchParams = window?.location?.search;
    // eslint-disable-next-line no-restricted-syntax
    for (const condition in redirectConditions) {
      if (searchParams.includes(condition)) {
        return redirectConditions[condition];
      }
    }
  };

  useEffect(() => {
    const query = parse(window.location.search);
    setParams(query);
    const adObject = adbutlerZones('rates', false, params, config);

    if (scData) {
      if (siteVertical) {
        const { search, href } = window.location;
        if (IlxRateZones(query, search) && !isSCLabs) {
          setIsILX(true);
        } else if (isSCLabs || siteVertical === 'hs_br') {
          setQsTy(true);
        } else if (search && search.includes('GCP')) {
          setAdIds(['336271', '336274', '336277', '336278']);
        } else if (
          search &&
          search.includes('zipRedirect') &&
          siteVertical === 'ins_health'
        ) {
          setAdIds(['444950', '597420']);
        } else if (search && search.includes('BCP')) {
          setAdIds(['336272', '336273', '336275', '336276']);
        } else if (
          search &&
          search.includes('HasSolar') &&
          query.version !== '1'
        ) {
          setAdIds(['472624', '472625', '472626', '472627', '472628']);
        } else if (href && href.includes('solarsurveyusa.com/rates/rate')) {
          setAdIds(['365658', '365659', '365660', '365661', '365662']);
        } else if (href && href.includes('trustedhomesurvey.com/rates/rate')) {
          setAdIds(['424237', '424238', '424239', '424240', '424241']);
        } else if (search && search.includes('noMed')) {
          setAdIds(['476611', '476612', '476613', '476614', '476615']);
        } else if (adObject[siteVertical]) {
          setAdIds(adObject[siteVertical]);
        } else {
          setAdIds(adObject.default);
        }
      } else {
        setAdIds(adObject.default);
      }
    } else {
      setAdIds(adObject.default);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const query = parse(window.location.search);
    (async () => {
      const ratePageJson = {
        uuid: storage('session', 'get', 'uuid'),
        CkmAffiliateId: query?.a ?? '52',
        CkmSubID1: query?.s1 ?? '',
        CkmCampaignID: query?.cpid ?? '',
        TestVariation: query?.version ?? '',
        CkmOfferId: config.offerId,
        site_url: config.scData.siteName || window?.location?.host,
        ExperimentId: await checkRateExperiment(),
        condition: findRedirectCondition(),
        Eject: true,
        frame: '/rate',
      };

      webHookTrack('Eject', ratePageJson);

      g4EventTracking('Eject', ratePageJson);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    storage('session', 'set', 'abZones', JSON.stringify(adIds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adIds]);

  useEffect(() => {
    if (!window.jtknet) {
      // loadScript('//insurance.mediaalpha.com/js/serve.js');
      loadScript('https://offers.printfingertech.net/js/jtknet.js');
    }

    if (!window.sh) {
      loadScript('//www.nextinsure.com/listingdisplay/loader/sh');
    }

    if (isQsTy) {
      const windowInterval = setInterval(() => {
        if (typeof window !== 'undefined' && window.jtknet) {
          // eslint-disable-next-line no-unused-expressions
          window?.jtknet?.doQsThankyou(siteVertical);
          clearInterval(windowInterval);
        }
      }, 100);
      setTimeout(() => {
        clearInterval(windowInterval);
      }, 10000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQsTy]);

  return (
    <RateContainer>
      {!isILX && !isQsTy && (
        <AdButler adButlerIds={adIds} adButlerAcctId="169214" path={path} />
      )}
      {isQsTy && (
        <>
          <div id="jtkNetAdTarget" style={{ margin: '0 auto 40px' }} />
          <CredibleDisclosure />
        </>
      )}
    </RateContainer>
  );
};

export default withThankyouWrapper(Rate);
